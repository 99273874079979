import React from 'react'
import { Link } from 'gatsby'

import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import AddMembership from 'src/components/add-membership/add-membership'

const CreateMembership = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Create Membership" />
      <AddMembership />
    </Layout>
  )
}

export default CreateMembership
