import React, { useState, useReducer } from 'react'
import InputLabel from '../input-label/input-label'
import Edit from '../edit/edit'
import * as styles from './membership-discounts-form.module.less'
import MembershipDiscountsInfo from '../membership-discounts-info/membership-discounts-info'
import DynamicDropdown from '../dynamic-dropdown/dynamic-dropdown'
import MembershipDiscountModal from '../membership-discount-modal/membership-discount-modal'
import { AddMembershipState } from 'src/utils/typedefs/membership'
import cx from 'classnames'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'
import { FormikProps, FieldArray, FastField, Field } from 'formik'
import _ from 'lodash'
import MembershipModal from '../membership-modal/membership-modal'
import { Grid } from '@material-ui/core'
import FormErrorMessage from '../form-error-message/form-error-message'
import IconAdornment from '../icon-adornment/icon-adornment'
import Adornment from '../adornment/adornment'
import RadioButtonGroup from '../radio-button-group/radio-button-group'
import { FaCalendar } from 'react-icons/fa'
import DatePicker from '../date-picker/date-picker'
import Input from '../input'
import Slider from '../slider/slider'
import { addDiscount as addDiscountInitialState } from '../../utils/typedefs/membership'

interface Props {
  formikProps: FormikProps<AddMembershipState>
}

// Mock data
const DISCOUNT_OPTIONS = [
  { value: 'addNewDiscount', label: '+ Add new discount', custom: true },
  { value: 'earlyBird', label: 'Early Bird 2019', multiple: false },
  { value: 'newMembers', label: 'New Members 50% OFF', multiple: false }
]

const DISCOUNT_TYPE_OPTIONS = [
  { label: 'Percentage', value: 'Percentage' },
  { label: 'Fixed amount', value: 'Fixed amount' }
]

const MembershipDiscountsForm: React.FC<Props> = ({ formikProps }: Props) => {
  const [discounts, setDiscounts] = useState<any>(new Set())

  const [show, setShow] = useState<boolean>(false)

  const { t } = useTranslation()
  const {
    values,
    values: { step, stepHistory, addDiscount },
    setFieldValue,
    setFieldTouched,
    touched: { addDiscount: touched },
    errors: { addDiscount: errors }
  } = formikProps

  const updateDiscounts = discount => {
    setDiscounts(new Set(discounts.add(discount)))
  }

  const changeStep = () => {
    setFieldValue('step', 4)
  }

  const nextStep = () => {
    setFieldValue(`stepHistory.${step}`, true)
    setFieldValue('step', step + 1)
  }

  const showModal = val => {
    setShow(val)
  }

  const handleNextStep = () => {
    // Check if Yup validation object has any errors, if true - return and touch all form fields
    if (!_.isEmpty(errors)) {
      return Object.keys(addDiscount).forEach(field =>
        setFieldTouched(`addDiscount.${field}`, true)
      )
    }

    setShow(false)
    addFormikDiscount(addDiscount)
    setFieldValue('addDiscount', addDiscountInitialState)
  }

  const initialState: any = []

  const reducer = (state, action) => {
    switch (action.type) {
      case 'add':
        return [...state, action.payload]
      case 'remove':
        return state.filter(discount => state.indexOf(discount) !== action.payload)
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const addFormikDiscount = discount => dispatch({ type: 'add', payload: discount })

  const renderFieldArrayForm = () => {
    return (
      <>
        <InputLabel className={styles.membershipLabel}>{t('name')}*</InputLabel>
        <Grid container>
          <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
            <FastField name={`addDiscount.name`} component={Input} classname={styles.input} />
          </Grid>
        </Grid>
        {errors?.name && touched?.name && <FormErrorMessage message={errors.name} />}
        <InputLabel className={styles.membershipLabel}>{t('discount type')}</InputLabel>
        <FastField
          name={`addDiscount.discountType`}
          options={DISCOUNT_TYPE_OPTIONS}
          setFieldValue={setFieldValue}
          component={RadioButtonGroup}
        />
        <InputLabel className={styles.membershipLabel}>{t('discount')}*</InputLabel>
        <Grid container>
          <Grid item xs={12} sm={7} md={6} lg={5} xl={2}>
            <IconAdornment>
              <Adornment input>%</Adornment>
              <Field
                name={`addDiscount.discountPercentage`}
                classname={styles.input}
                component={Input}
              />
            </IconAdornment>
          </Grid>
        </Grid>
        {errors?.discountPercentage && touched?.discountPercentage && (
          <FormErrorMessage message={errors.discountPercentage} />
        )}
        <InputLabel className={styles.sliderLabel}>{t('used with discounts')}?</InputLabel>
        <FastField
          name={`addDiscount.withOtherDiscounts`}
          setFieldValue={setFieldValue}
          component={Slider}
        />
        <InputLabel className={styles.sliderLabel}>{t('used multiple times')}?</InputLabel>
        <FastField
          name={`addDiscount.multipleTimes`}
          setFieldValue={setFieldValue}
          component={Slider}
        />
        <InputLabel className={styles.membershipLabel}>{t('expiry date')}</InputLabel>
        <Grid container>
          <Grid item xs={12} sm={7} md={6} lg={5} xl={2}>
            <IconAdornment>
              <Adornment>
                <FaCalendar className={styles.adornmentIcon} />
              </Adornment>
              <Field
                name={`addDiscount.expiryDate`}
                setFieldValue={setFieldValue}
                onChange={date => setFieldValue(`addDiscount.expiryDate`, date)}
                component={DatePicker}
                classname={styles.datepicker}
              />
            </IconAdornment>
          </Grid>
        </Grid>
        <p className={styles.subLabel}>{t('expiry date placeholder')}</p>
      </>
    )
  }

  const renderForm = () => (
    <div className={styles.alignContainer}>
      <InputLabel className={styles.membershipLabel}>{t('select a discount')}</InputLabel>
      <DynamicDropdown
        action={discount => addFormikDiscount(discount)}
        showModal={val => showModal(val)}
        options={DISCOUNT_OPTIONS}
      />
      <div className={styles.discountContainer}>
        {state.length > 0 &&
          state.map((discount, index) => {
            return (
              <div key={index} id={index} className={styles.selectedDiscount}>
                <span>{discount.name || discount.label}</span>
                <button
                  type="button"
                  className={styles.link}
                  onClick={() =>
                    dispatch({
                      type: 'remove',
                      payload: index
                    })
                  }
                >
                  {t('remove')}
                </button>
              </div>
            )
          })}
      </div>
      <MembershipModal
        show={show}
        title={t('add discount')}
        setShow={setShow}
        actionButtons={[
          {
            content: 'Confirm',
            props: {
              type: 'button',
              onClick: show => {
                // handleConfirmModal()
                // show(false)
              },
              spacing: { margins: { md: 'left' } }
            }
          }
        ]}
        cancelButton={{
          content: 'Close',
          props: {
            type: 'button',
            onClick: show => show(false)
          }
        }}
        formikProps={formikProps}
        addFormikDiscount={addFormikDiscount}
        handleNextStep={handleNextStep}
      >
        {renderFieldArrayForm()}
      </MembershipModal>
      <Button size="md" type="button" onClick={nextStep}>
        {t('continue')}
      </Button>
    </div>
  )

  const headingStyle = step === 0 ? styles.headingStyle : {}

  return (
    <div className={styles.container}>
      <div className={cx(styles.header, headingStyle)}>
        <h1 className={styles.heading}>{t('discounts')}</h1>
        {step !== 4 && stepHistory[4] && <Edit className={styles.editBtn} action={changeStep} />}
      </div>
      {step === 4 ? renderForm() : <MembershipDiscountsInfo values={values} />}
    </div>
  )
}

export default MembershipDiscountsForm
