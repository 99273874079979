import React, { useState, useRef, useEffect, ReactNode } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  Modifier,
  SelectionState,
  convertToRaw
} from 'draft-js'
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection'
import * as styles from './rich-text-editor.module.less'
import {
  FaBold,
  FaExpandArrowsAlt,
  FaUnderline,
  FaEraser,
  FaRedo,
  FaItalic,
  FaListOl,
  FaListUl,
  FaQuoteRight,
  FaUndo
} from 'react-icons/fa'
import { Grid } from '@material-ui/core'

type BasicButtonProps = {
  children: ReactNode
  handleClick?: any
}

const BasicButton: React.FC<BasicButtonProps> = ({ children, handleClick }: BasicButtonProps) => {
  return (
    <button type="button" className={styles.button} onClick={handleClick}>
      {children}
    </button>
  )
}

const TextEditor = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [rawState, setRawState] = useState({})

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const onChange = editorState => {
    const contentState = editorState.getCurrentContent()
    setRawState({ ...rawState, ...convertToRaw(contentState) })
    setEditorState(editorState)
  }

  const handleClick = type => {
    switch (type) {
      case 'BOLD':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
        break
      case 'ITALIC':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
        break
      case 'UNDERLINE':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
        break
      case 'CUT':
        onCutText()
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'CUT'))
        break
      case 'blockquote':
        setEditorState(RichUtils.toggleBlockType(editorState, 'blockquote'))
        break
      case 'ordered-list-item':
        setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))
        break
      case 'unordered-list-item':
        setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
        break
      case 'undo':
        setEditorState(EditorState.undo(editorState))
        break
      case 'redo':
        setEditorState(EditorState.redo(editorState))
        break
      case 'delete':
        onDeleteClick()
        break
    }
  }

  const focusEditor = () => (editor.current as any).focus()

  const editor = useRef(null)

  const onDeleteClick = () => {
    let contentState = editorState.getCurrentContent()
    const firstBlock = contentState.getFirstBlock()
    const lastBlock = contentState.getLastBlock()
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true
    })
    contentState = Modifier.removeRange(contentState, allSelected, 'backward')
    setEditorState(EditorState.push(editorState, contentState, 'remove-range'))
  }

  const onCutText = () => {
    const selected = getFragmentFromSelection(editorState)
    const selectedText = selected.map(x => x.getText()).join('\n')
  }

  useEffect(() => {
    focusEditor()
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} sm={11} md={8} lg={6} xl={4}>
        <div className={styles.controller}>
          <BasicButton handleClick={() => handleClick('BOLD')}>
            <FaBold />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('ITALIC')}>
            <FaItalic />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('UNDERLINE')}>
            <FaUnderline />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('undo')}>
            <FaUndo />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('redo')}>
            <FaRedo />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('blockquote')}>
            <FaQuoteRight />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('ordered-list-item')}>
            <FaListOl />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('unordered-list-item')}>
            <FaListUl />
          </BasicButton>
          <BasicButton handleClick={() => handleClick('delete')}>
            <FaEraser />
          </BasicButton>
        </div>

        <Editor
          ref={editor}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

export default TextEditor
