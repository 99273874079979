import {
  Adornment,
  Dropdown,
  IconAdornment,
  TextInput
} from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import cx from 'classnames'
import { Field, FormikProps } from 'formik'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import Dropdown from '../dropdown/dropdown'
// import Adornment from '../adornment/adornment'
// import IconAdornment from '../icon-adornment/icon-adornment'
import { FaCalendar } from 'react-icons/fa'
import { getFirstOptionValue } from 'src/utils/helper/membership'
import { AddMembershipState } from 'src/utils/typedefs/membership'
import Button from '../button/button'
import DatePicker from '../date-picker/date-picker'
import Edit from '../edit/edit'
import FormErrorMessage from '../form-error-message/form-error-message'
import InputLabel from '../input-label/input-label'
import MembershipDatesInfo from '../membership-dates-info/membership-dates-info'
import * as styles from './membership-dates-form.module.less'

interface Props {
  formikProps: FormikProps<AddMembershipState>
}

const MembershipDatesForm: React.FC<Props> = ({ formikProps }: Props) => {
  const { t } = useTranslation()
  const {
    values,
    values: { step, stepHistory, dates },
    setFieldValue,
    setFieldTouched,
    touched: { dates: touched },
    errors: { dates: errors }
  } = formikProps

  const RENEWALS_OPTIONS = [
    { label: t('off'), value: 'off' },
    { label: t('monthly'), value: 'monthly' },
    { label: t('annually'), value: 'annually' }
  ]

  // Set default values for select fields
  useEffect(() => {
    setFieldValue('renewals', getFirstOptionValue(RENEWALS_OPTIONS))
  }, [])

  const changeStep = () => {
    setFieldValue('step', 1)
  }
  const nextStep = () => {
    // Check if Yup validation object has any errors
    if (!_.isEmpty(errors)) {
      return Object.keys(dates).forEach(field => setFieldTouched(`dates.${field}`, true))
    }
    setFieldValue(`stepHistory.${step}`, true)
    setFieldValue('step', step + 1)
  }

  const isEndDateDisabled = values.dates.renewals === 'annually'

  const render = () => {
    switch (step) {
      case 0:
        return <></>
      case 1:
        return (
          <div className={styles.subContainer}>
            <InputLabel className={styles.membershipLabel}>{t('renewals')}</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <Field
                  name="dates.renewals"
                  options={RENEWALS_OPTIONS}
                  className={styles.explanation}
                  setFieldValue={setFieldValue}
                  component={Dropdown}
                  selectClassname={styles.select}
                  dropdownClassname={styles.dropdown}
                />
              </Grid>
            </Grid>
            <InputLabel className={styles.membershipLabel}>{t('start date')}*</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <IconAdornment>
                  <Adornment>
                    <FaCalendar className={styles.adornmentIcon} />
                  </Adornment>
                  <Field
                    name="dates.startDate"
                    setFieldValue={setFieldValue}
                    onChange={date => setFieldValue('dates.startDate', date)}
                    component={DatePicker}
                    classname={styles.datepicker}
                  />
                </IconAdornment>
              </Grid>
            </Grid>
            {errors?.startDate && touched?.startDate && (
              <FormErrorMessage message={errors.startDate} />
            )}
            <InputLabel className={styles.membershipLabel}>{t('end date')}*</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <IconAdornment>
                  <Adornment>
                    <FaCalendar className={styles.adornmentIcon} />
                  </Adornment>
                  <Field
                    name="dates.endDate"
                    setFieldValue={setFieldValue}
                    onChange={date => setFieldValue('dates.endDate', date)}
                    component={DatePicker}
                    disabled={isEndDateDisabled}
                    classname={styles.datepicker}
                  />
                </IconAdornment>
              </Grid>
            </Grid>
            {errors?.endDate && touched?.endDate && <FormErrorMessage message={errors.endDate} />}
            <InputLabel className={styles.membershipLabel}>{t('grace period days')}</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <Field name="dates.gracePeriod" component={TextInput} className={styles.input} />
              </Grid>
            </Grid>
            <Button size="md" spacing={{ margins: { lg: 'top' } }} type="button" onClick={nextStep}>
              {t('continue')}
            </Button>
          </div>
        )
      default:
        return <MembershipDatesInfo values={values} />
    }
  }

  const headingStyle = () => {
    switch (step) {
      case 0:
        return styles.headingStyle
      case 1:
        return {}
      default:
        return {}
    }
  }

  return (
    <div className={styles.container} onClick={() => console.log(values.dates.renewals)}>
      <div className={cx(styles.header, headingStyle())}>
        <h1 className={styles.heading}>{t('dates')}</h1>
        {(step > 1 || (stepHistory[1] && step !== 1)) && (
          <Edit className={styles.editBtn} action={changeStep} />
        )}
      </div>
      {render()}
    </div>
  )
}

export default MembershipDatesForm
