export const flexiblePricingRules = {
  cost: '',
  property: '',
  category: '',
  paymentModel: '',
  minimumFee: '',
  maximumFee: ''
}

export const addDiscount = {
  name: '',
  discountType: '',
  discountPercentage: '',
  withOtherDiscounts: false,
  multipleTimes: false,
  expiryDate: null
}

export type StepHistory = {
  0: boolean
  1: boolean
  2: boolean
  3: boolean
  4: boolean
}

export type PaymentMethods = {
  creditCard: boolean
  invoice: boolean
}

export type CourtTypes = {
  indoor: boolean
  outdoor: boolean
  floodlit: boolean
  court78: boolean
  court36: boolean
  total: boolean
}

export type MembershipFigures = {
  adult: boolean
  junior: boolean
  family: boolean
  senior: boolean
  social: boolean
  total: boolean
}

export type RolesToCapture = {
  president: boolean
  tennisDirector: boolean
  generalManager: boolean
  membershipCoordinator: boolean
}
export type CoachRolesToCapture = {
  head: boolean
  coach: boolean
  assistant: boolean
}

export type AddDiscount = {
  name: string
  discountType: string
  discountPercentage: string
  withOtherDiscounts: boolean
  multipleTimes: boolean
  expiryDate: Date | null
}

export type FlexiblePricingRules = {
  cost: string
  property: string
  category: string
  paymentModel: string
  minimumFee: string
  maximumFee: string
}

export type Cost = {
  pricingModel: string
  flexiblePricingRules: FlexiblePricingRules[]
  prorataPricing: boolean
  paymentMethods: PaymentMethods
  invoiceAddress: string
}

export interface MembershipDetails {
  name: string
  code: string
  status: string
  type: string
  category: string
  description: string
  benefits: string
  ageRestriction: boolean
  minAge: string
  maxAge: string
}

export interface Dates {
  renewals: string
  startDate: Date | null
  endDate: Date | null
  gracePeriod: number
}

export type AddMembershipState = {
  membershipDetails: MembershipDetails
  dates: Dates
  courts: string
  members: string
  programs: string
  managementTeam: string
  coachingTeam: string
  cost: Cost
  step: number
  courtTypes: CourtTypes
  membershipFigures: MembershipFigures
  rolesToCapture: RolesToCapture
  coachRolesToCapture: CoachRolesToCapture
  addDiscount: AddDiscount
  stepHistory: StepHistory
}

export const addMembershipValues: AddMembershipState = {
  membershipDetails: {
    name: '',
    code: '',
    status: '',
    type: '',
    category: '',
    description: '',
    benefits: '',
    ageRestriction: false,
    minAge: '',
    maxAge: ''
  },
  dates: {
    renewals: '',
    startDate: null,
    endDate: null,
    gracePeriod: 0
  },
  courts: '',
  members: '',
  programs: '',
  managementTeam: '',
  coachingTeam: '',
  cost: {
    pricingModel: '',
    flexiblePricingRules: [flexiblePricingRules],
    prorataPricing: false,
    paymentMethods: {
      creditCard: false,
      invoice: false
    },
    invoiceAddress: ''
  },
  step: 0,
  courtTypes: {
    indoor: false,
    outdoor: false,
    floodlit: false,
    court78: false,
    court36: false,
    total: false
  },
  membershipFigures: {
    adult: false,
    junior: false,
    family: false,
    senior: false,
    social: false,
    total: false
  },
  rolesToCapture: {
    president: false,
    tennisDirector: false,
    generalManager: false,
    membershipCoordinator: false
  },
  coachRolesToCapture: {
    head: false,
    coach: false,
    assistant: false
  },
  addDiscount: addDiscount,
  stepHistory: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false
  }
}

export interface Options {
  value: string
  label: string
}
