import { Checkbox } from '@clubspark-react/clubspark-react-tools'
import cx from 'classnames'
import { FastField, Field, FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import CheckboxLabels from '../checkbox/checkbox'
import { AddMembershipState } from 'src/utils/typedefs/membership'
import Button from '../button/button'
import Edit from '../edit/edit'
import InputLabel from '../input-label/input-label'
import MembershipDatesInfo from '../membership-dates-info/membership-dates-info'
import Slider from '../slider/slider'
import * as styles from './membership-form.module.less'

interface Props {
  formikProps: FormikProps<AddMembershipState>
}

const MembershipForm: React.FC<Props> = ({ formikProps }: Props) => {
  const { t } = useTranslation()
  const {
    values,
    values: { step, stepHistory },
    setFieldValue
  } = formikProps

  const SliderLabel = { true: t('yes'), false: t('no') }

  const courtTypes = {
    indoor: t('number of indoor courts'),
    outdoor: t('number of outdoor courts'),
    floodlit: t('number of courts', { type: 'floodlit' }),
    court78: t('number inch courts', { type: '78"' }),
    court60: t('number inch courts', { type: '60"' }),
    court36: t('number inch courts', { type: '36"' }),
    total: t('total number courts')
  }

  const membershipFigures = {
    adult: t('number of members', { type: 'adult' }),
    junior: t('number of members', { type: 'junior' }),
    family: t('number of members', { type: 'family' }),
    senior: t('number of members', { type: 'senior' }),
    social: t('number of members', { type: 'social' }),
    total: t('total members')
  }

  const rolesToCapture = {
    president: t('president'),
    tennisDirector: t('tennis director'),
    generalManager: t('general manager'),
    membershipCoordinator: t('membership co-ordinator')
  }

  const coachRolesToCapture = {
    head: t('head coach'),
    coach: t('coach'),
    assistant: t('assistant coach')
  }
  const changeStep = () => {
    setFieldValue('step', 2)
  }

  const nextStep = () => {
    setFieldValue(`stepHistory.${step}`, true)
    setFieldValue('step', step + 1)
  }

  const renderCourts = () => {
    if (values.courts) {
      return (
        <>
          <InputLabel className={styles.membershipLabel}>{t('court type figures')}</InputLabel>
          {Object.entries(courtTypes).map(([key, value]) => (
            <Field
              key={key}
              name={`courtTypes.${key}`}
              label={value}
              component={Checkbox}
              setFieldValue={setFieldValue}
            />
          ))}
        </>
      )
    }
  }

  const renderMembershipFigures = () => {
    if (values.members) {
      return (
        <>
          <InputLabel className={styles.membershipLabel}>{t('membership type figures')}</InputLabel>
          {Object.entries(membershipFigures).map(([key, value]) => (
            <FastField
              key={key}
              name={`membershipFigures.${key}`}
              label={value}
              component={Checkbox}
              setFieldValue={setFieldValue}
            />
          ))}
        </>
      )
    }
  }

  const renderRolesToCapture = () => {
    if (values.managementTeam) {
      return (
        <>
          <InputLabel className={styles.membershipLabel}>{t('roles to capture')}</InputLabel>
          {Object.entries(rolesToCapture).map(([key, value]) => (
            <FastField
              key={key}
              name={`rolesToCapture.${key}`}
              label={value}
              component={Checkbox}
              setFieldValue={setFieldValue}
            />
          ))}
        </>
      )
    }
  }

  const renderCoachRolesToCapture = () => {
    if (values.coachingTeam) {
      return (
        <>
          <InputLabel className={styles.membershipLabel}>{t('roles to capture')}</InputLabel>
          {Object.entries(coachRolesToCapture).map(([key, value]) => (
            <FastField
              key={key}
              name={`coachRolesToCapture.${key}`}
              label={value}
              component={Checkbox}
              setFieldValue={setFieldValue}
            />
          ))}
        </>
      )
    }
  }

  const render = () => {
    switch (step) {
      case 0:
        return <></>
      case 2:
        return (
          <div className={styles.subContainer}>
            <p>{t('information to capture')}</p>
            <InputLabel className={styles.membershipLabel}>{t('courts')}</InputLabel>
            <FastField
              name="courts"
              setFieldValue={setFieldValue}
              label={SliderLabel}
              component={Slider}
            />
            {renderCourts()}

            <InputLabel className={styles.membershipLabel}>{t('members')}</InputLabel>
            <FastField
              name="members"
              setFieldValue={setFieldValue}
              label={SliderLabel}
              component={Slider}
            />
            {renderMembershipFigures()}
            <InputLabel className={styles.membershipLabel}>{t('programs')}</InputLabel>
            <FastField
              name="programs"
              setFieldValue={setFieldValue}
              label={SliderLabel}
              component={Slider}
            />
            <InputLabel className={styles.membershipLabel}>{t('management team')}</InputLabel>
            <FastField
              name="managementTeam"
              setFieldValue={setFieldValue}
              label={SliderLabel}
              component={Slider}
            />
            {renderRolesToCapture()}
            <InputLabel className={styles.membershipLabel}>{t('coaching team')}</InputLabel>
            <FastField
              name="coachingTeam"
              setFieldValue={setFieldValue}
              label={SliderLabel}
              component={Slider}
            />
            {renderCoachRolesToCapture()}
            <Button size="md" spacing={{ margins: { lg: 'top' } }} type="button" onClick={nextStep}>
              {t('continue')}
            </Button>
          </div>
        )
      default:
        return <MembershipDatesInfo values={values} />
    }
  }

  const headingStyle = () => {
    switch (step) {
      case 0:
        return styles.headingStyle
      case 1:
        return {}
      default:
        return {}
    }
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.header, headingStyle())}>
        <h1 className={styles.heading}>{t('form details')}</h1>
        {(step > 2 || (step !== 2 && stepHistory[2])) && (
          <Edit className={styles.editBtn} action={changeStep} />
        )}
      </div>
      {render()}
    </div>
  )
}

export default MembershipForm
