import React, { Component } from 'react'
import Select from 'react-select'
import * as styles from './dynamic-dropdown.module.less'
import classnames from 'classnames/bind'
import { Options } from '../../utils/typedefs/membership'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.data.custom ? '#01a2ff' : 'black'
  })
}

interface Props {
  options: Options[]
  action?: Function
  showModal?: any
}

const DynamicDropdown: React.FC<Props> = ({ action, options, showModal }: Props) => {
  const cx = classnames.bind(styles)
  const handleChange = (newValue: any, actionMeta: any) => {
    if (newValue && action) {
      const { label, value, custom } = newValue
      if (custom) {
        showModal(true)
      } else {
        action(newValue)
      }
    }
  }
  const handleInputChange = (inputValue: any, actionMeta: any) => {}

  return (
    <Select
      className={cx('container')}
      classNamePrefix="cs-react-select"
      styles={customStyles}
      onChange={handleChange}
      // onInputChange={handleInputChange}
      placeholder=""
      value=""
      options={options}
      isSearchable={false}
    />
  )
}

export default DynamicDropdown
