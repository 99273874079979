// extracted by mini-css-extract-plugin
export var bodyBase = "membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodyLarge = "membership-details-info-module--body-large--433QP membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodyLargeBold = "membership-details-info-module--body-large-bold--8MLwn membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodyRegular = "membership-details-info-module--body-regular--BqCom membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodyRegularBold = "membership-details-info-module--body-regular-bold--Aeq3X membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodySmall = "membership-details-info-module--body-small--4+gHd membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var bodySmallBold = "membership-details-info-module--body-small-bold--qnJwW membership-details-info-module--body-base--yB5Ik membership-details-info-module--site-font--5o1pC";
export var borderTop = "membership-details-info-module--border-top--7gEiZ";
export var breakWordContainer = "membership-details-info-module--break-word-container--eo4No";
export var buttonIconBase = "membership-details-info-module--button-icon-base--RQmYm";
export var clickLink = "membership-details-info-module--click-link--mFj4G";
export var container = "membership-details-info-module--container--VZkr6";
export var dropdownBase = "membership-details-info-module--dropdown-base--UO0jd";
export var dropdownSelectBase = "membership-details-info-module--dropdown-select-base--XuZAk membership-details-info-module--text-input--XhHB0";
export var flexCol = "membership-details-info-module--flex-col--k5oEv";
export var formErrorMessage = "membership-details-info-module--form-error-message--CkiC9";
export var h3 = "membership-details-info-module--h3--TNO9n";
export var h4 = "membership-details-info-module--h4--ccrAB";
export var hoverLink = "membership-details-info-module--hover-link--0NGrZ";
export var hoverRow = "membership-details-info-module--hover-row--PQYOc";
export var itemA = "membership-details-info-module--item-a--dHqKY";
export var itemB = "membership-details-info-module--item-b--sxxOl";
export var itemC = "membership-details-info-module--item-c--BNIqs";
export var itemD = "membership-details-info-module--item-d--F--cz";
export var itemE = "membership-details-info-module--item-e--ZFQap";
export var label = "membership-details-info-module--label--lIpWN";
export var labelValue = "membership-details-info-module--label-value--rXGXY";
export var membershipContainer = "membership-details-info-module--membership-container--xnFMC membership-details-info-module--flex-col--k5oEv membership-details-info-module--primary-border--YMbH7";
export var membershipHeader = "membership-details-info-module--membership-header--ooGbG";
export var membershipHeading = "membership-details-info-module--membership-heading--kzBL1";
export var membershipLabel = "membership-details-info-module--membership-label--sYaa4";
export var moreFiltersBorderClass = "membership-details-info-module--more-filters-border-class--+nWaU";
export var pageBg = "membership-details-info-module--page-bg--bE8z4";
export var pointer = "membership-details-info-module--pointer--lQiP4";
export var primaryBorder = "membership-details-info-module--primary-border--YMbH7";
export var shadowBoxLight = "membership-details-info-module--shadow-box-light--Yqm0o";
export var siteFont = "membership-details-info-module--site-font--5o1pC";
export var slideDownAndFade = "membership-details-info-module--slideDownAndFade--4NKaO";
export var slideLeftAndFade = "membership-details-info-module--slideLeftAndFade--8TBX3";
export var slideRightAndFade = "membership-details-info-module--slideRightAndFade--Z6-GG";
export var slideUpAndFade = "membership-details-info-module--slideUpAndFade--wcxwA";
export var statusDecoration = "membership-details-info-module--status-decoration--CW5sr";
export var textInput = "membership-details-info-module--text-input--XhHB0";
export var textInverted = "membership-details-info-module--text-inverted--6b8rM";
export var textMediumDark = "membership-details-info-module--text-medium-dark--wFvKw";
export var tooltipFont = "membership-details-info-module--tooltipFont--iDJ2s";
export var unstyledButton = "membership-details-info-module--unstyled-button--KJSZU";