import React, { useEffect, useState } from 'react'
import InputLabel from '../input-label/input-label'
import { Field, FormikProps, FieldArray } from 'formik'
import Input from '../input'
import Edit from '../edit/edit'
import * as styles from './membership-cost-form.module.less'
import Button from '../button/button'
import MembershipDatesInfo from '../membership-dates-info/membership-dates-info'
import CheckboxLabels from '../checkbox/checkbox'
import Slider from '../slider/slider'
import Dropdown from '../dropdown/dropdown'
import IconAdornment from '../icon-adornment/icon-adornment'
import Adornment from '../adornment/adornment'
import cx from 'classnames'
import { AddMembershipState, flexiblePricingRules } from 'src/utils/typedefs/membership'
import { getFirstOptionValue } from 'src/utils/helper/membership'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import RadioButtonGroup from 'src/components/radio-button-group/radio-button-group'

interface Props {
  formikProps: FormikProps<AddMembershipState>
}

const MembershipCostForm: React.FC<Props> = ({ formikProps }: Props) => {
  const { t } = useTranslation()
  const [rule, setRule] = useState(['rule'])
  const {
    values,
    values: { step, stepHistory },
    setFieldValue
  } = formikProps

  const PRICING_MODEL_OPTIONS = [
    { label: t('fixed cost'), value: 'fixed cost' },
    { label: t('flexible pricing'), value: 'flexible pricing' }
  ]

  const PROPRETY_OPTIONS = [
    {
      value: 'please select',
      label: t('please select')
    },
    {
      value: 'courts',
      label: t('courts')
    },
    {
      value: 'members',
      label: t('members')
    },
    {
      value: 'programs',
      label: t('programs')
    },
    {
      value: 'teams',
      label: t('teams')
    },
    {
      value: 'minimum',
      label: t('minimum')
    },
    {
      value: 'maximum',
      label: t('maximum')
    }
  ]

  const CATEGORY_OPTIONS = [
    {
      value: 'please select',
      label: t('please select')
    },
    {
      value: 'indoor',
      label: t('indoor')
    },
    {
      value: 'outdoor',
      label: t('outdoor')
    },
    {
      value: 'grass',
      label: t('grass')
    },
    {
      value: '78"',
      label: '78"'
    }
  ]

  const PAYMENT_MODEL_OPTIONS = [
    {
      value: 'please select',
      label: t('please select')
    },
    {
      value: 'per unit',
      label: t('per unit')
    },
    {
      value: 'fixed',
      label: t('fixed')
    }
  ]

  // Set default values for select fields
  useEffect(() => {
    setFieldValue('cost.pricingModel', getFirstOptionValue(PRICING_MODEL_OPTIONS))
  }, [])

  const changeStep = () => {
    setFieldValue('step', 3)
  }

  const nextStep = () => {
    setFieldValue(`stepHistory.${step}`, true)
    setFieldValue('step', step + 1)
  }

  const renderMinMaxFee = index => {
    if (values.cost.flexiblePricingRules[index].paymentModel === 'per unit') {
      return (
        <>
          <div className={styles.subContainer}>
            <InputLabel className={styles.membershipLabel}>{t('minimum fee')}</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <IconAdornment>
                  <Adornment input>$</Adornment>
                  <Field
                    name={`cost.flexiblePricingRules.${index}.minimumFee`}
                    size="xs"
                    component={Input}
                    classname={styles.input}
                  />
                </IconAdornment>
              </Grid>
            </Grid>
          </div>
          <div className={styles.subContainer}>
            <InputLabel className={styles.membershipLabel}>{t('maximum fee')}</InputLabel>
            <Grid container>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <IconAdornment>
                  <Adornment input>$</Adornment>
                  <Field
                    name={`cost.flexiblePricingRules.${index}.maximumFee`}
                    size="xs"
                    component={Input}
                    classname={styles.input}
                  />
                </IconAdornment>
              </Grid>
            </Grid>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const renderPriceRule = index => {
    return (
      <div className={styles.flexiblePricingContainer} key={index}>
        <div className={styles.subContainer}>
          <InputLabel className={styles.membershipLabel}>{t('property')}</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Field
                name={`cost.flexiblePricingRules.${index}.property`}
                options={PROPRETY_OPTIONS}
                setFieldValue={setFieldValue}
                component={Dropdown}
                selectClassname={styles.select}
                dropdownClassname={styles.dropdown}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.subContainer}>
          <InputLabel className={styles.membershipLabel}>{t('category')}</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
              <Field
                name={`cost.flexiblePricingRules.${index}.category`}
                options={CATEGORY_OPTIONS}
                setFieldValue={setFieldValue}
                component={Dropdown}
                selectClassname={styles.select}
                dropdownClassname={styles.dropdown}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.subContainer}>
          <InputLabel className={styles.membershipLabel}>{t('payment model')}</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
              <Field
                name={`cost.flexiblePricingRules.${index}.paymentModel`}
                options={PAYMENT_MODEL_OPTIONS}
                setFieldValue={setFieldValue}
                component={Dropdown}
                selectClassname={styles.select}
                dropdownClassname={styles.dropdown}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.subContainer}>
          <InputLabel className={styles.membershipLabel}>{t('cost')}</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
              <IconAdornment>
                <Adornment input>$</Adornment>
                <Field
                  name={`cost.flexiblePricingRules.${index}.cost`}
                  size="xs"
                  component={Input}
                  classname={styles.input}
                />
              </IconAdornment>
            </Grid>
          </Grid>
        </div>
        {renderMinMaxFee(index)}
      </div>
    )
  }

  const renderCostInput = () => {
    if (values.cost.pricingModel === 'flexible pricing') {
      return (
        <FieldArray
            name="cost.flexiblePricingRules"
            render={arrayHelpers => (
              <>
                {values.cost.flexiblePricingRules.map((rule, index) => (
                  <Grid container key={index}>
                    {renderPriceRule(index)}
                  </Grid>
                ))}
                <button
                  className={styles.addRule}
                  type="button"
                  onClick={() => arrayHelpers.push(flexiblePricingRules)}
                >
                  +&nbsp;{t('add rule')}
                </button>
              </>
            )}
          />
      )
    } else {
      return (
        <>
          <InputLabel className={styles.membershipLabel}>{t('cost')}*</InputLabel>
          <IconAdornment>
            <Adornment input>$</Adornment>
            <Field name="cost.cost" size="xs" component={Input} />
          </IconAdornment>
        </>
      )
    }
  }

  const render = () => {
    switch (step) {
      case 0:
        return <></>
      case 3:
        return (
          <div className={styles.alignContainer}>
            <InputLabel className={styles.membershipLabel}>{t('pricing model')}</InputLabel>
            <Field
              name="cost.pricingModel"
              options={PRICING_MODEL_OPTIONS}
              setFieldValue={setFieldValue}
              component={RadioButtonGroup}
            />
            {values.cost.pricingModel === 'flexible pricing' && renderCostInput()}
            <InputLabel className={styles.membershipLabel}>{t('pro-rata pricing')}</InputLabel>
            <Field
              name="cost.prorataPricing"
              label={{ true: t('yes'), false: t('no') }}
              setFieldValue={setFieldValue}
              component={Slider}
            />
            <InputLabel className={styles.membershipLabel}>{t('payment methods')}</InputLabel>
            <Field
              name="cost.paymentMethods.creditCard"
              value="creditCard"
              label="Credit / debit card"
              setFieldValue={setFieldValue}
              component={CheckboxLabels}
            />
            <Field
              name="cost.paymentMethods.invoice"
              value="invoice"
              label="Invoice"
              setFieldValue={setFieldValue}
              component={CheckboxLabels}
            />
            <InputLabel className={styles.membershipLabel}>{t('invoice address')}</InputLabel>
            <Field
              name="cost.invoiceAddress"
              options={[{ label: `x`, value: `x` }]}
              setFIeldValue={setFieldValue}
              component={RadioButtonGroup}
            />
            <Button size="md" spacing={{ margins: { lg: 'top' } }} type="button" onClick={nextStep}>
              {t('continue')}
            </Button>
          </div>
        )
      default:
        return <MembershipDatesInfo values={values} />
    }
  }

  const headingStyle = () => {
    switch (step) {
      case 0:
        return styles.headingStyle
      case 1:
        return {}
      default:
        return {}
    }
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.header, headingStyle())}>
        <h1 className={styles.heading}>{t('costs')}</h1>
        {(step > 3 || (step !== 3 && stepHistory[3])) && (
          <Edit className={styles.editBtn} action={changeStep} />
        )}
      </div>
      {render()}
    </div>
  )
}

export default MembershipCostForm
