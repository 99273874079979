import { CustomModal, ModalButton } from '@clubspark-react/clubspark-react-tools'
import { FormikProps } from 'formik'
import React, { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '../button/button'

interface ModalButtonProps extends Pick<ButtonProps, Exclude<keyof ButtonProps, 'onClick'>> {
  onClick?: (setShow: (show: boolean) => any) => any
}

interface Props {
  show: boolean
  setShow: (val: boolean) => void
  actionButtons?: ModalButton[]
  cancelButton?: ModalButton
  formikProps?: FormikProps<any>
  addFormikDiscount?: any
  title: string
  children: ReactNode
  handleNextStep?: any
}

const MembershipModal: React.FC<Props> = ({
  show,
  setShow,
  actionButtons,
  cancelButton,
  formikProps,
  addFormikDiscount,
  title,
  children,
  handleNextStep
}: Props) => {
  const { t } = useTranslation()

  const addShow = useCallback(
    (show: boolean, props?: ModalButtonProps) => {
      return {
        ...(props ?? {}),
        onClick: () => (props?.onClick ? props.onClick(setShow) : setShow(show))
      }
    },
    [setShow]
  )

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      actionButtons={actionButtons}
      cancelButton={cancelButton?.content || t('close')}
      title={title}
      children={children}
      handleNextStep={handleNextStep}
    />
  )
}

export default MembershipModal
