// extracted by mini-css-extract-plugin
export var adornmentIcon = "membership-details-form-module--adornment-icon--e1NFe";
export var ageResContainer = "membership-details-form-module--age-res-container--L3WVj";
export var alignContainer = "membership-details-form-module--align-container--o9QMs";
export var bodyBase = "membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodyLarge = "membership-details-form-module--body-large--Nv53e membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodyLargeBold = "membership-details-form-module--body-large-bold--ZNj3U membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodyRegular = "membership-details-form-module--body-regular--RsZqc membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodyRegularBold = "membership-details-form-module--body-regular-bold--SEQ5s membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodySmall = "membership-details-form-module--body-small--C+-Ff membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var bodySmallBold = "membership-details-form-module--body-small-bold--pcTYH membership-details-form-module--body-base--WOa-- membership-details-form-module--site-font--GL8d7";
export var borderTop = "membership-details-form-module--border-top--7wOZI";
export var breakWordContainer = "membership-details-form-module--break-word-container---vYSO";
export var buttonIconBase = "membership-details-form-module--button-icon-base--ZzAAX";
export var clickLink = "membership-details-form-module--click-link--nmoB6";
export var container = "membership-details-form-module--container--ULfGk membership-details-form-module--flex-col--7iWqK membership-details-form-module--primary-border--Zr0a+";
export var dropdown = "membership-details-form-module--dropdown--6qLrI";
export var dropdownBase = "membership-details-form-module--dropdown-base--aqC+5";
export var dropdownSelectBase = "membership-details-form-module--dropdown-select-base--DPUAc membership-details-form-module--text-input--xz90F";
export var editBtn = "membership-details-form-module--edit-btn--1dfuW";
export var errorMessage = "membership-details-form-module--error-message--Kpw0V membership-details-form-module--form-error-message--nQK5p";
export var explanation = "membership-details-form-module--explanation--f66LG";
export var explanationDropdown = "membership-details-form-module--explanation-dropdown--YBIJ0";
export var flexCol = "membership-details-form-module--flex-col--7iWqK";
export var formErrorMessage = "membership-details-form-module--form-error-message--nQK5p";
export var h3 = "membership-details-form-module--h3--yVzuI";
export var h4 = "membership-details-form-module--h4--g8MuH";
export var header = "membership-details-form-module--header--toWXo membership-details-form-module--membership-header--XXqhd";
export var heading = "membership-details-form-module--heading--jDVLo membership-details-form-module--membership-heading--NCJAV";
export var hoverLink = "membership-details-form-module--hover-link--sZs2f";
export var hoverRow = "membership-details-form-module--hover-row--zA-l+";
export var input = "membership-details-form-module--input--DgkRf";
export var inputContainer = "membership-details-form-module--input-container--Kr10s";
export var membershipContainer = "membership-details-form-module--membership-container--7mWuZ membership-details-form-module--flex-col--7iWqK membership-details-form-module--primary-border--Zr0a+";
export var membershipHeader = "membership-details-form-module--membership-header--XXqhd";
export var membershipHeading = "membership-details-form-module--membership-heading--NCJAV";
export var membershipLabel = "membership-details-form-module--membership-label--ZgBC6 membership-details-form-module--membership-label--ZgBC6";
export var moreFiltersBorderClass = "membership-details-form-module--more-filters-border-class--2KvzV";
export var pageBg = "membership-details-form-module--page-bg--PdSy3";
export var pointer = "membership-details-form-module--pointer--j7cDw";
export var primaryBorder = "membership-details-form-module--primary-border--Zr0a+";
export var select = "membership-details-form-module--select--LKmIb";
export var shadowBoxLight = "membership-details-form-module--shadow-box-light--UjEMo";
export var siteFont = "membership-details-form-module--site-font--GL8d7";
export var slideDownAndFade = "membership-details-form-module--slideDownAndFade--5Gyd4";
export var slideLeftAndFade = "membership-details-form-module--slideLeftAndFade--mu+in";
export var slideRightAndFade = "membership-details-form-module--slideRightAndFade--tUbT9";
export var slideUpAndFade = "membership-details-form-module--slideUpAndFade--0sAsQ";
export var statusDecoration = "membership-details-form-module--status-decoration--QZ-g3";
export var subContainer = "membership-details-form-module--sub-container--ZwFNi membership-details-form-module--flex-col--7iWqK";
export var testStyle = "membership-details-form-module--test-style--auclU";
export var textInput = "membership-details-form-module--text-input--xz90F";
export var textInverted = "membership-details-form-module--text-inverted--XGqph";
export var textMediumDark = "membership-details-form-module--text-medium-dark--mex0G";
export var tooltipFont = "membership-details-form-module--tooltipFont--lNUWM";
export var topLabel = "membership-details-form-module--top-label--95f5p";
export var unstyledButton = "membership-details-form-module--unstyled-button--bMRKR";